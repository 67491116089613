#site-navigation-wrap, #site-navigation, #menu-main-nav-bar-menu {
  height: 80px;
  width: 100%;
  display: block;
  background: none;
}

.menu-item:hover .sub-menu {
  display: block;
  visibility: visible;
  opacity: 1;
}

#mobile-dropdown {
  height: 0;
  display: block !important;
  transition: height .2s !important;
}

#mobile-dropdown .sub-menu {
  display: none !important;
}

#mobile-dropdown .sub-menu.open {
  display: block !important;
}

#mobile-dropdown .dropdown-toggle:after {
  display: none;
}

#mobile-menu-trigger a {
  outline: none !important;
}

@media screen and (max-width: 776px) {
  #site-navigation-wrap {
    display: none;
  }
  #mobile-menu-trigger {
    display: inline-block;
    position: absolute;
    right: 10px;
  }
}