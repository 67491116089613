.vapetasia-custom-grid-item {
    text-align: center;
}

.vapetasia-image-object {
    width: 300px;
    height: 300px;
}

@media screen and (max-width: 700px) {
    .vapetasia-image-object, .vapetasia-image .mini-on-mobile img {
        width: 250px !important;
    }
}