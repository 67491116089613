.vape-font.h-2 {
    font-family: "rift", Montserrat;
    font-size: 36px !important;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 600;
}
.vape-font.h-3 {
    text-align: center;
    color: #000000;
    font-family: "Montserrat", Montserrat;
    font-size: 14px !important;
    font-weight: bold;
    letter-spacing: 0px;
}