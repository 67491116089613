/*UNTIL WE HAVE ANIMATING HERO TEXT, FORCE TO DISPLAY*/
.force-visible {
  visibility: visible !important;
}
.hero-paragraph-text {
  min-height: 250px;
}

/*RELEVANT HERO CLASSES*/

.vapetasia-191
  .vapetasia-element.vapetasia-element-page-hero:not(
    .vapetasia-motion-effects-element-type-background
  ),
.vapetasia-191
  .vapetasia-element.vapetasia-element-HomeHero
  > .vapetasia-motion-effects-container
  > .vapetasia-motion-effects-layer {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.vapetasia-191
  .vapetasia-element.vapetasia-element-page-hero
  > .vapetasia-background-overlay {
  background-image: url("https://cdn2.vapetasia.com/img/hero/gash.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.vapetasia-191 .vapetasia-element.vapetasia-element-StoreLocatorHero {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0px 0px 199px 0px;
}

/*
EVERY SECTION BELOW THE HERO SHOULD HAVE A TOP PADDING
*/
.vapetasia-element-page-hero + section {
  padding-top: 100px;
}

/*dim the gash overlay when screen is XL*/
@media screen and (min-width: 1600px) {
  .vapetasia-background-overlay,
  .hero-paragraph-detail {
    opacity: 0.3 !important;
    border-bottom: 0 !important;
  }
  #hero-description {
    color: #ffffff;
  }
  #hero-description-air {
    color: #000;
  }
  #hero-description-air .hero-paragraph-text-air {
    color: #000 !important;
  }
  .hero-paragraph-text-air {
    color: #000 !important;
  }
}
.widget-title {
  border-color: #faa500;
}
.vapetasia-element-page-hero + section {
  padding-top: 10px;
}

/*
ADJUST HERO SPACER AT VARYING SIZES
*/
.vapetasia-background-overlay {
  border-bottom: 20px solid white;
}
@media screen and (max-width: 1300px) {
  .vapetasia-background-overlay {
    border-bottom: 60px solid white;
  }
}
@media screen and (max-width: 1200px) {
  .vapetasia-background-overlay {
    border-bottom: 100px solid white;
  }
}
@media screen and (max-width: 1000px) {
  .vapetasia-background-overlay {
    border-bottom: 100px solid white;
  }
}
@media screen and (max-width: 900px) {
  .hero-height-gap {
    height: 80px !important;
  }
  .vapetasia-background-overlay {
    border-bottom: 120px solid white;
  }
}
@media screen and (max-width: 800px) {
  .hero-height-gap {
    height: 60px !important;
  }
  .vapetasia-background-overlay {
    border-bottom: 140px solid white;
  }
  /*hide the sidebar*/
  .hero-paragraph-detail {
    display: none !important;
  }
  /*shrink the paragraph*/
  .vapetasia-element-page-hero .hero-paragraph-text {
    font-size: 22px;
    min-height: 225px;
  }
}
@media screen and (max-width: 600px) {
  #second-hero-spacer {
    display: none !important;
  }
  .hero-height-gap {
    height: 0 !important;
  }
  .vapetasia-background-overlay {
    border-bottom: 160px solid white;
  }
  /*remove title offset*/
  .vapetasia-element-page-hero .vapetasia-widget-container {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 30px !important;
  }
  /*shrink the title*/
  .vapetasia-element-page-hero .vapetasia-heading-title {
    font-size: 64px !important;
    text-align: center;
  }
  /*shrink the paragraph*/
  .hero-paragraph-text {
    padding-top: 60px;
    font-size: 20px !important;
  }
}
@media screen and (max-width: 360px) {
  .hero-height-gap {
    height: 0 !important;
  }
  .vapetasia-background-overlay {
    border-bottom: 250px solid white;
  }
  /*shrink the title*/
  .vapetasia-element-page-hero .vapetasia-heading-title {
    font-size: 32px !important;
    text-align: center;
  }
  /*shrink the paragraph*/
  .hero-paragraph-text {
    padding-top: 100px;
    /* font-size: 18px !important; */
  }
}

@media screen and (max-width: 300px) {
  .hero-height-gap {
    height: 0 !important;
  }
  /* .vapetasia-background-overlay {
    border-bottom: 250px solid white;
  }
  /*shrink the title*/
  .vapetasia-element-page-hero .vapetasia-heading-title {
    font-size: 32px !important;
    text-align: center;
  }
  /*shrink the paragraph*/
  .hero-paragraph-text {
    padding-top: 100px;
  }
}
