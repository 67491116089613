.vt-carousel {
    /* height: 200px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.vt-testimonial {
    display: flex;
    align-items: center;
    justify-content: center;
}