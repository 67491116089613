.location-results {
  font-weight: bold;
  font-size: 18px;
}

/**
ZIP CODE ENTRY
**/
#wholesaler-search {
  display: flex;
  width: 100%;
  justify-content: right;
  margin-bottom: 40px;
}
#wholesaler-search input {
  width: 250px;
  max-width: 70%;
  padding: 10px;
  font-size: 18px;
}
#wholesaler-search input::placeholder {
  color: #cccccc;
}
#wholesaler-search button {
  background-color: #424242;
  color: #ffffff;
  font-family: "Rift";
  font-size: 22px;
  font-style: italic;
}

/**
LOCATION REQUEST
**/
#set-location-trigger {
  line-height: 1;
  padding: 10px 0;
}
.location-button {
  display: block;
  cursor: pointer;
}

#loading-distributors {
  padding: 60px 0;
  padding-bottom: 1px solid #cccccc;
  text-align: center;
  width: 100%;
}

#pagination-controls {
  width: 100%;
  display: flex;
  justify-content: right;
  border-bottom: 2px solid gray;
  padding: 15px 0;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 16px;
}
#pagination-controls a {
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;
  text-decoration: underline;
}
#pagination-controls .active {
  text-decoration: none;
}

#crmWebToEntityForm tr,
#crmWebToEntityForm td {
  padding: 6px;
  border-spacing: 0px;
  border-width: 0px;
  display: block;
  width: 100%;
}
@media screen and (max-width: 600px) {
  #crmWebToEntityForm tr,
  #crmWebToEntityForm td {
    box-sizing: border-box;
    padding-left: 20px;
    width: 100% !important;
  }
  #crmWebToEntityForm table {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .distributor-paragraph {
    font-family: "Montserrat", Montserrat;
    font-size: 18px;
    text-align: center;
    padding-bottom: 10px;
  }
  #wholesale-section h2,
  #wholesale-section p {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  #distributors-section h2,
  #distributors-section p {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.wholesale-heading-url {
  color: #7a7a7a;
  font-family: "rift", Montserrat;
  font-size: 35px;
  font-style: italic;
}

.wholesale-heading-phone {
  color: #faa500;
  font-size: 2rem;
}

/* #wholesale-section a:hover {
  color: #7a7a7a !important;
  transition: color 1s ease;
} */

.vapetasia-button-wholesale {
  color: #fff !important;
  align-items: center;
  justify-content: center;
  background-color: #faa500 !important;
}

.vapetasia-button-wholesale a {
  color: #fff !important;
  font-family: "rift", Sans-serif !important;
  font-weight: bold !important;
  font-style: italic !important;
  font-size: 1em;
}

#wholesale-section .vapetasia-button {
  background-color: #faa500 !important;
  font-family: "rift", Sans-serif !important;
  font-weight: bold !important;
  font-style: italic !important;
  font-size: 2.2em;
}

.vapetasia-button-wholesale:hover {
  color: gray;
  transform: scale(1.1);
}

.distributor-paragraph {
  font-family: "Montserrat", Montserrat;
  font-size: 24px;
  text-align: center;
  padding-bottom: 35px;
}

.site-url {
  text-transform: uppercase;
  font-family: "rift", Sans-serif !important;
  font-size: 34px;
}

.site-url a {
  color: gray !important;
}

/* #wholesale-section {
  margin-bottom: 25px;
} */

.distributors-section h2 {
  line-height: 1 !important;
  margin-bottom: 0;
}

.distributors-section hr {
  border: 1px solid black;
  width: 50%;
  margin: 25px 25% 50px 25% !important;
  align-content: center;
}

.distributors-section a:hover {
  color: black !important;
  transition: color 1s ease;
}
