.verify-modal {
  font-family: "rift", Sans-serif !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn2.vapetasia.com/img/Age Verify BG 2.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  opacity: 0;
  background-color: #ffffff;
  transition: all ease-in-out 0.5s;
  display: block;
}

.verify-modal-hidden {
  opacity: 0;
  visibility: hidden;
}
.verify-modal-shown {
  opacity: 1;
  visibility: visible;
}

.verify-modal-box {
  background-color: #ffffff;
  box-shadow: 3px 2px 1px 0 #cccccc;
  border-radius: 30px;
  padding: 30px;
  margin: 20vh auto 0 auto;
  display: inline-block;
  max-width: 500px;
  min-width: 350px;
  width: 30%;
}

.verify-modal-logo {
  width: 100%;
  height: 70px;
  background-image: url("https://cdn2.vapetasia.com/img/Vapetasia_Logo_2_small.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.verify-modal-title {
  font-size: 48px;
  color: #000000;
  font-weight: bold;
  font-style: italic;
  margin: 20px 0 10px 0;
  text-align: center;
  line-height: 1 !important;
  text-transform: uppercase;
}

.verify-modal-desc {
  font-size: 18px;
  color: #000000;
  font-weight: normal;
  text-align: center;
  text-transform: none !important;
  line-height: 1 !important;
}

.verify-modal-buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin: 20px auto;
}

.age-verify-button {
  display: inline-block;
  margin: 0 10px;
  background-color: #f9a704;
  padding: 10px;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  font-style: italic;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  font-size: 16px;
}
.age-verify-button:hover {
  transform: scale(1.1);
  color: #ffffff !important;
}

/* .verify-locale-button {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 50%;
  line-height: 50px;
  font-size: 28px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin: 0 5px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.verify-locale-button:hover {
  transform: scale(1.1);
}
.btn-us {
  background-color: #074097;
}
.btn-eu {
  background-color: #679705;
}
.btn-ca {
  background-color: #980605;
}
.btn-as {
  background-color: #7902aa;
}
.btn-au {
  background-color: #fb8a02;
} */
