.easy-notification-bar{background:#ffffff;color:#000000;font-size:30px;} 

.easy-notification-bar {
  line-height: 1 !important;
  }
  .easy-notification-bar-container {
  border: 4px solid black;
  padding: 10px;
  box-sizing: border-box;
  }
  @media screen and (max-width: 600px) {
  .easy-notification-bar-container {
  font-size: 22px;
  }
  }
 .active-strength {
  background-color: #FFA500 !important;
 }
 .dropdown-menu .sub-menu {
  border-top: 3px solid #FFA500;
 }
 /*div[class*="btn-strength-"]  {
  display: none;
 }
 div[class*="btn-strength-0"] {
  display: block;
 }*/