/*.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;     
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
  }
*/

.vapetasia-element-page-hero {
  background-color: #000000;
}

.vapetasia-custom-paragraph {
  color: #000000;
  font-size: 25px;
}

.vapetasia-our-flavors-text {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

#blog-section .vapetasia-button {
  background-color: #faa500 !important;
  font-family: "rift", Sans-serif !important;
  font-weight: bold !important;
  font-style: italic !important;
  font-size: 2em !important;
}
