.blog-heading-container {
  background-color: #faa500 !important;
  padding: 10px;
}

.blog-heading {
  color: white !important;
}

#blog-section .vapetasia-button {
  background-color: #faa500 !important;
  font-family: "rift", Sans-serif !important;
  font-weight: bold !important;
  font-style: italic !important;
  font-size: 2em !important;
}

#blog-section .vapetasia-widget-text-editor {
  text-align: center;
  color: #000000;
  font-size: 25px;
}

#blog-section .vapetasia-heading-title {
  text-align: center !important;
}

.post-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  font-family: Monterrat;
  font-size: 14px;
}

.vapetasia-blog-entry {
  border-bottom: 1px solid grey;
  padding: 40px 0;
}