.showProductModal {
  display: flex;
}
.hideProductModal {
  display: none !important;
}
.vapetasia-product-modal-wrapper {
  padding: 20px 30px 0 30px;
}

.dialog-close-button {
  font-size: 35px !important;
}

.dialog-close-button:hover {
  color: var(--first-color);
}

.pop-up-text p {
  font-size: 16px;
}

.pop-up-heading {
  font-style: italic;
}

.vapetasia-product-popup .vapetasia-heading-title {
  font-style: italic !important;
}

.vapetasia-custom-padding-top-30 {
  padding-top: 30px !important;
}
.vapetasia-custom-image-ratio-75 img {
  width: 75% !important;
}
.vapetasia-custom-image-ratio-50 {
  width: 50% !important;
}

.popup-text-online-stores p {
  font-size: 16px !important;
}
