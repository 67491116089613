.team-vapetasia {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* .team-vapetasia-download {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
} */

@media (max-width: 768px) {
  .team-vapetasia {
    padding: 10px;
  }
}
