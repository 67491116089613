.iframe {
    width: 100%;
    height:100%;
    min-height:600px;
}

/* Store Locator Hero */
/* .vapetasia-191 .vapetasia-element.vapetasia-element-StoreLocatorHero:not(.vapetasia-motion-effects-element-type-background), .vapetasia-191 .vapetasia-element.vapetasia-element-HomeHero > .vapetasia-motion-effects-container > .vapetasia-motion-effects-layer {
    background-image: url("https://cdn2.vapetasia.com/img/hero/StoreLocator.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.vapetasia-191 .vapetasia-element.vapetasia-element-StoreLocatorHero > .vapetasia-background-overlay {
    background-image: url("https://cdn2.vapetasia.com/img/hero/gash.png");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.vapetasia-191 .vapetasia-element.vapetasia-element-StoreLocatorHero {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 0px 0px 199px 0px;
} */

/* Store Locator Hero Text */
/* .vapetasia-191 .vapetasia-element.vapetasia-element-StoreLocatorHero {
    margin-top: 0px;
    margin-bottom: -400px;
    padding: 50px 0px 0px 0px;
}

.vapetasia-191 .vapetasia-element.vapetasia-element-StoreLocatorHero {    
    color: #252525;
    font-family: "Montserrat", Montserrat;
    font-size: 28px;
}

.vapetasia-191 .vapetasia-element.vapetasia-element-StoreLocatorHero > .vapetasia-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
} */