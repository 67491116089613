.warning-container {
    margin: 30px;
    display: flex;
    border: 4px solid black;
    padding: 10px;
    box-sizing: border-box;
    justify-content: center;
}

.warning {
  line-height: 1;
  text-transform: uppercase;
  justify-content: center;
  font-size: 1.5em;
}

@media screen and (max-width: 600px) {
  .warning-container {
    font-size: 18px;
    text-align: center;
  }
}