/* Advocacy Hero Text */
/* .vapetasia-191 .vapetasia-element.vapetasia-element-AdvocacyHero {
    margin-top: 0px;
    margin-bottom: -205px;
    padding: 151px 0px 0px 0px;
}

.vapetasia-191 .vapetasia-element.vapetasia-element-AdvocacyHero {
    margin-top: 0px;
    margin-bottom: -300px;
    padding: 200px 0px 0px 0px;
} */

/* Advocacy Hero */
/* .vapetasia-191 .vapetasia-element.vapetasia-element-AdvocacyHero:not(.vapetasia-motion-effects-element-type-background), .vapetasia-191 .vapetasia-element.vapetasia-element-AdvocacyHero > .vapetasia-motion-effects-container > .vapetasia-motion-effects-layer {
    background-image: url("https://cdn2.vapetasia.com/img/hero/Advocacy.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.vapetasia-191 .vapetasia-element.vapetasia-element-AdvocacyHero > .vapetasia-background-overlay {
    background-image: url("https://cdn2.vapetasia.com/img/hero/gash.png");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.vapetasia-191 .vapetasia-element.vapetasia-element-AdvocacyHero {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 0px 0px 199px 0px;
} */


/* .vapetasia-section {
    font-family:"monterrat",Sans-serif !important;
    font-size: 1.2em;
} */

#advocacy-section .vapetasia-button {
    background-color: #FAA500 !important;
    font-family:"rift",Sans-serif !important;
    font-weight: bold !important;
    font-style: italic !important;
    font-size: 2em !important;
}

#advocacy-section .vapetasia-button:hover {
    transform: scale(1.1);
}
