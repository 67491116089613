.vapetasia-custom-grid {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.vapetasia-section-custom-grid {
    padding-top: 60px;
}

@media screen and (max-width: 800px) {
    .vapetasia-custom-grid {
        max-width: 100%;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .vapetasia-custom-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}