.compliance-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  font-family: Monterrat;
  /* padding-top: 56.25%; 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.compliance-paragraph {
  font-size: 16px !important;
  font-family: Montserrat;
}
